<template>
  <div>
    <MiniBanner />
    <div class="txt txt1">
      <p></p>
      <p style="line-height: 2em">
        <span style="white-space: normal; color: rgb(0, 0, 0); font-size: 14px"
          >根据大会举办地相关防疫政策，参会代表需通过大会网站观看学术内容直播。</span
        >
      </p>
      <p style="line-height: 2em">
        <span style="font-family: 微软雅黑, Microsoft YaHei"
          ><span
            style="
              background-color: rgb(255, 255, 255);
              color: rgb(0, 112, 192);
            "
            ><strong>本次会议只接受网上在线注册，线上观看免注册费</strong></span
          ><span
            style="
              background-color: rgb(255, 255, 255);
              color: rgb(84, 141, 212);
            "
            >。</span
          ><span style="font-size: 14px; color: rgb(0, 0, 0)"><br /></span
        ></span>
      </p>
      <p style="line-height: 2em">
        <span style="font-family: 微软雅黑, Microsoft YaHei"
          ><strong style="color: rgb(0, 112, 192)">学分领取规则：</strong
          ><span style="font-size: 14px; color: rgb(0, 0, 0)"><br /></span
        ></span>
      </p>
      <p style="line-height: 2em">
        <span
          style="color: rgb(0, 0, 0); font-family: 微软雅黑, Microsoft YaHei"
          >北京医学教育委员会将授予此次大会北京市继续教育项目I类学分5分。（注册签到时请认真填写注册信息，填写错误将无法获得学分）</span
        >
      </p>
      <p style="line-height: 2em">
        <span style="color: rgb(0, 112, 192)"
          >根据级教委要求需要同时满足以下条件</span
        >
      </p>
      <p style="line-height: 2em">
        <span
          style="color: rgb(0, 0, 0); font-family: 微软雅黑, Microsoft YaHei"
          >1.全程观看直播满3小时计1学分，满15小时计5学分，每天上限2学分。</span
        >
      </p>
      <p style="line-height: 2em">
        <span
          style="color: rgb(0, 0, 0); font-family: 微软雅黑, Microsoft YaHei"
          >2.直播间内将于每天10:00和14:00弹出签到框，每次持续10分钟。
          全会共计签到6次。</span
        >
      </p>
      <p style="line-height: 2em">
        <span
          style="color: rgb(0, 0, 0); font-family: 微软雅黑, Microsoft YaHei"
          >3.北京学员直接受予学分，请填写级教IC卡号，地址处填无。京外学员请填写学分本邮寄地址。</span
        >
      </p>
      <p>
        <span
          style="
            box-sizing: border-box;

            font-weight: bolder;
            font-size: 16px;
            white-space: normal;
            background-color: rgb(255, 255, 255);
            color: rgb(255, 0, 0);
          "
          >温馨提示：学分授予只以直播间观看时长为准，回看不计入学分领取范畴之内。</span
        >
      </p>
    </div>
    
    <van-overlay :show="show" @click="clickOverlay">
      <div class="wrapper">
        <div class="imgBox">
          <img class="block" src="@/assets/mobile/creditpic.png" alt="" />
          <div class="crossBox">
            <van-icon name="cross" />
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import MiniBanner from "@/components/miniBanner/index";
import { mapActions } from "vuex";

export default {
  name: "MiniCreditInfo",
  data() {
    return {
      show: true,
    };
  },
  components: {
    MiniBanner,
  },
  methods: {
    clickOverlay() {
      this.show = false;
    },
    ...mapActions({
      setTitle: "setTitleFunc",
    }),
  },
  created() {
    this.setTitle("学分注册");
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.imgBox {
  position: relative;
  width: 90%;
}
.crossBox {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1000;
}

.block {
  width: 100%;
}
.title {
  border-bottom: 1px solid #e5f3fc;
  padding: 18px;
  margin-top: 22px;
  position: relative;
}
.title > h4 {
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  line-height: 1.4em;
  padding-left: 24px;
  position: relative;
  margin: 0;
}
.title > h4:after {
  content: "";
  position: absolute;
  left: -5px;
  top: 5px;
  width: 20px;
  height: 20px;
  background-color: #c10000;
}
p {
  margin: 0;
}
.txt {
  font-size: 16px;
  color: #797979;
  line-height: 1.5em;
  padding: 25px 30px;
}
.txt img {
  max-width: 100%;
}
</style>